import { useState } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams } from "react-router-dom";
import styles from './ProductVariant.module.scss'
import { observer } from "mobx-react-lite";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from 'react-i18next';
import { getProductVariantsByProductId } from '../../services';
import { ProductVariantDto } from '../../types/ProductVariantDto';
// import { sample } from '../../assets/images';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { useAppStore } from '../../stores/AppStore';

const ProductVariant = observer(() => {
    const { productStore } = useAppStore();
    const { t } = useTranslation();
    const { id } = useParams(); // Extracts `id` from the URL
    const productId = id ? parseInt(id, 10) : undefined;
    const navigate = useNavigate(); 
    const { data: responseVariants, isLoading, isError } = useQuery({
        queryKey: ['productVariant', productId],
        queryFn: () => {
            if (productId !== undefined) {
                return getProductVariantsByProductId(productId);
            }
            return Promise.reject(new Error('Product ID is not defined'));
        },
        enabled: productId !== undefined, // Only run query if productGroupId is defined
    });

    const [selectedVariantId, setSelectedVariantId] = useState<number>(0);
    const [variantSelected, setVariantSelected] = useState<ProductVariantDto>();

    const [stateArrVariants, setStateArrVariants] = useState<string[]>([]);
    const handleVariantClick = (productVariantId: number) => {
        setSelectedVariantId(productVariantId);
        
        const obj = productVariants?.find((item) => item.id === productVariantId); //lấy một object đầu tiên được tìm thấy mà thoả điều kiện
        setVariantSelected(obj);
        let groupImage = obj?.groupImageURL;
        console.log('obj: ', obj);
        if(groupImage !== null){
            const resultArray = groupImage!.split('w');
            console.log(resultArray);
            setStateArrVariants(resultArray);

        }
    };
    const [stateBool_isOpenDialog, setStateBool_isOpenDialog] = useState(false); // boolean
    const [stateString_selectedImg, setStateString_selectedImg] = useState<string>(''); // string

    const selectImg = (imgUrl: string) => {
        setStateBool_isOpenDialog(true);
        setStateString_selectedImg(imgUrl);
    }
    const cancelDialog = () => {
        setStateBool_isOpenDialog(false);
        setStateString_selectedImg('');
    }
    let productVariants: ProductVariantDto[] = [];
    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error loading product details</div>;
    if (!responseVariants) return <div>No product data available</div>;
    if (responseVariants && responseVariants.data.statusCode !== undefined && responseVariants.data.message) {
        if (responseVariants.data.statusCode !== 200) {
            return <div>{responseVariants.data.message}</div>;
        } else {
            productVariants = responseVariants.data.data; // Product data
            // console.log('productVariants: ', productVariants);
        }
    }

    return (
        <div className={clsx(styles.ProductVariant)}>
            <div className={clsx(styles.breadcums)}>
               <p onClick={()=>navigate(`/productgroup`, { replace: true })}>{t('Group Product')}</p>
               <p className={clsx(styles.arrow)}>{'>'}</p>
               <p onClick={()=>navigate(`/product/${productStore.productGroupId}`, { replace: true })}>{t(productStore.productGroupName)}</p>
               <p className={clsx(styles.arrow)}>{'>'}</p>
               <p>{t(productStore.productName)}</p>
            </div>
            <p className={clsx(styles.title)}>{t(productVariants[0]?.product?.name ?? 'No product name available')}</p>
            <div className={clsx(styles.container)}>
                <div className={clsx(styles.left)}>
                {productVariants !== undefined && productVariants.length > 0 &&
                    productVariants.map((obj: ProductVariantDto) => (
                        <div
                            className={clsx(styles.productVariant, {
                                [styles.selected]: selectedVariantId === obj.id
                            })}
                            key={obj.id}
                            onClick={() => handleVariantClick(obj.id)}
                        >
                            <div className={clsx(styles.productImage)}>
                                {/* <img src={`./../../asset/productImage/${obj.imageURL}`} alt="not available" /> */}
                                <img src={obj.imageURL || ""} alt="not available" />
                            </div>
                            <div className={clsx(styles.productInfo)}>
                                <p className={clsx(styles.productName)}>
                                    {t(obj.variantName)}
                                </p>
                            </div>
                        </div>
                    ))
                }
                </div>
                <div className={clsx(styles.right)}>
                    {
                        selectedVariantId === 0 ? 
                        (
                            <div className={clsx(styles.details)}>
                                <p className={clsx(styles.note)}>{t('Select a product on the left to see details here')}</p>
                                <div className={clsx(styles.skeleton)}>
                                    <Stack spacing={2}>
                                        <Skeleton variant="rounded" width="100%" height={30}/>
                                        <Skeleton variant="rounded" width="90%" height={30} />
                                        <Skeleton variant="rounded" width="100%" height={30} />
                                        <Skeleton variant="rounded" width="90%" height={30} />
                                    </Stack>

                                  
                                </div>
                            </div>
                        ) 
                        : 
                        (
                            <div className={clsx(styles.details)}>
                                <div className={clsx(styles.row)}>
                                    <p className={clsx(styles.key)}>{t('Name')}:</p>
                                    <p className={clsx(styles.value)}>{t(variantSelected?.variantName ?? '')}</p>
                                </div>
                                <div className={clsx(styles.row)}>
                                    <p className={clsx(styles.key)}>{t('Weight')}:</p>
                                    <p className={clsx(styles.value)}>{variantSelected?.weight}</p>
                                </div>
                                <div className={clsx(styles.row)}>
                                    <p className={clsx(styles.key)}>{t('Dimensions')}:</p>
                                    <p className={clsx(styles.value)}>{variantSelected?.dimensions}</p>
                                </div>
                                <div className={clsx(styles.row)}>
                                    <p className={clsx(styles.key)}>{t('Color')}:</p>
                                    <p className={clsx(styles.value)}>{variantSelected?.color}</p>
                                </div>
                                <div className={clsx(styles.row)}>
                                    <p className={clsx(styles.key)}>{t('Size')}:</p>
                                    <p className={clsx(styles.value)}>{variantSelected?.size}</p>
                                </div>
                                <div className={clsx(styles.imageVariantContainer)}>
                                    {   stateArrVariants.length > 0 &&
                                        stateArrVariants.map((imageName) => {
                                            return ( 
                                                <img onClick={() => selectImg(imageName)} src={`./../../asset/productImage/${imageName}`} alt="not available" />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className={clsx(styles.backShadow, { [styles.appear]: stateBool_isOpenDialog })} onClick={cancelDialog}>
            </div>
            <div className={clsx(styles.dialogImg, { [styles.appear]: stateBool_isOpenDialog })}>
                <img src={`./../../asset/productImage/${stateString_selectedImg}`} alt="hinh giao dich" />
            </div>
        </div>
    )
})
export default ProductVariant;