import clsx from 'clsx';
import styles from './Product.module.scss';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useAppStore } from '../../stores/AppStore';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from "@tanstack/react-query";
import { getProductByGroupId } from '../../services';
import AnimatedSection from '../../components/common/AnimatedSection/AnimatedSection';
import { one, two, three, four, five, six } from '../../assets/images';
import Divider from '../../components/common/Divider';
import { useState } from 'react';
import { ProductDto } from '../../types/ProductDto';
const Product = observer(() => {
    const { productStore } = useAppStore();
    const { t } = useTranslation();
    const { id } = useParams(); // Extracts `id` from the URL
    const navigate = useNavigate(); 

    // Convert id to number if necessary
    const productGroupId = id ? parseInt(id, 10) : undefined;
    // Mục đích của expandedProducts là để lưu trạng thái mở rộng hay thu gọn của cái description đc click vào
    const [expandedProducts, setExpandedProducts] = useState<{ [key: number]: boolean }>({});
    /*
    { [key: number]: boolean } is a TypeScript type definition indicating that expandedProducts is an object where:
        The keys are numbers (representing product IDs).
        The values are booleans (indicating whether a product's description is expanded or not).
    */
    // cập nhật state cho object expandedProducts
    const handleToggle = (productId: number) => {
        setExpandedProducts(prevState => ({
        ...prevState,
        [productId]: !prevState[productId]
        }));
    };

    const { data: responseProducts, isLoading, isError } = useQuery({
        queryKey: ['product', productGroupId],
        queryFn: () => {
            if (productGroupId !== undefined) {
                return getProductByGroupId(productGroupId);
            }
            return Promise.reject(new Error('Product ID is not defined'));
        },
        enabled: productGroupId !== undefined, // Only run query if productGroupId is defined
    });
    let products: ProductDto[] = [];
    if (isLoading) return <div>Loading...</div>;
    if (isError) return <div>Error loading product details</div>;
    if (!responseProducts) return <div>No product data available</div>;
    if (responseProducts && responseProducts.data.statusCode !== undefined && responseProducts.data.message) {
        if (responseProducts.data.statusCode !== 200) {
            return <div>{responseProducts.data.message}</div>;
        } else {
            products = responseProducts.data.data; // Product data
            // console.log('products: ', products);
        }
    }
    console.log('responseProducts: ', responseProducts);
    // Define background images for each productGroupId
    const backgroundImages: { [key: number]: string } = {
        1: one,
        2: two,
        3: three,
        4: four,
        5: five,
        6: six,
    };

    // Ensure productGroupId is valid or default to 1
    const productImage = productGroupId && backgroundImages[productGroupId] ? backgroundImages[productGroupId] : one;

    const selectProduct = (productId: number, name: string) => {
    
        productStore.updateProductId(productId);
        productStore.updateProductName(name);
        navigate(`/productvariant/${productId}`, { replace: true });
    
    }
    return (
        <div className={clsx(styles.Product)}>
            <AnimatedSection className={clsx(styles.structure)}>
                <div className={clsx(styles.bgColor)}
                    style={{
                        background: `url(${productImage}) no-repeat center center`,
                        backgroundSize: 'cover' // Ensures the image covers the entire area
                    }}
                >
                    <div className={clsx(styles.sample)}>
                    {products[0]?.group?.groupName && <p className={clsx(styles.title)}>{t(products[0].group.groupName)}</p>}
                    {products[0]?.group?.description && <p className={clsx(styles.intro)}>{t(products[0].group.description)}</p>}
                    </div>
                </div>
            </AnimatedSection>
            <div className={clsx(styles.breadcums)}>
               <p onClick={()=>navigate(`/productgroup`, { replace: true })}>{t('Group Product')}</p>
               <p className={clsx(styles.arrow)}>{'>'}</p>
               <p>{t(productStore.productGroupName)}</p>
            </div>
            <div className={clsx(styles.productContainer)}>
                <p className={clsx(styles.title)}>{t('Products')}</p>
                <Divider width={90}/>
                <div className={clsx(styles.productList)}
                    // nếu width of screen >=1300px mà chỉ có 2 sản phẩm thì sẽ hiển thị 2 cột, còn 3 sản phẩm sẽ hiển thị 3 cột 
                    style={{
                        display: 'grid',
                        gridTemplateColumns: window.innerWidth >= 1300
                            ? (products.length === 2 ? '1fr 1fr' : '1fr 1fr 1fr')
                            : undefined  // No inline grid styles for widths < 1300px
                    }}
                >
                    {
                        products.map((obj: ProductDto) => {
                            const words = obj.description.split(' ');
                            const isExpanded = expandedProducts[obj.id];
                            const visibleWords = isExpanded ? words : words.slice(0, 20);
                            const truncatedDescription = visibleWords.join(' ') + (words.length > 20 && !isExpanded ? ' ...' : '');

                            return ( 
                                <div className={clsx(styles.product)} key={obj.id}>
                                    <div className={clsx(styles.productImage)}>
                                        {/* <img 
                                            src={obj.imageURL && imageMapping[obj.imageURL] ? imageMapping[obj.imageURL] : ''} 
                                            alt={obj.name} 
                                        /> */}

                                        {/* <img src={`./../../asset/productImage/${obj.imageLocalName}`} alt="not available" /> */}
                                        <img src={obj.imageURL || ""} alt="not available" />
                                    </div>
                                    <div className={clsx(styles.productInfo)}>
                                        <p className={clsx(styles.productName)}>{t(obj.name)}</p>
                                        <p className={clsx(styles.productDesc)}>
                                            {t(truncatedDescription)}
                                            {words.length > 20 && (
                                                <span
                                                    onClick={() => handleToggle(obj.id)}
                                                    className={clsx(styles.viewMoreBtn)}
                                                >
                                                    {isExpanded ? 'View Less' : 'View More'}
                                                </span>
                                            )}
                                        </p>
                                        <p className={clsx(styles.view)}  onClick={()=>selectProduct(obj.id, obj.name)}>{t('View Details')}</p>

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
});

export default Product;
