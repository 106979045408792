import React, { useEffect, useRef, useState } from 'react';
import { observer } from "mobx-react-lite";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import HTMLFlipBook from 'react-pageflip';
// import { CeilingPanel2D, CeilingPanel3D, CNC, Door, FlutedPanelWallPanelCabinetProfileCompositeDoor, Marble, PimaIntroduction, Shelf, WallAndFlutePanel } from '../../assets/pdf';
import { CatalogPimaAllProducts, DoorCompositePima2024} from '../../assets/pdf';

import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import './CatalogBook.scss';
import Lottie from 'lottie-react';
import animation_book_open from '../../assets/animation/animation_book_open.json';
import { useParams } from 'react-router-dom';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useTranslation } from 'react-i18next';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const width = 595;
const height = 862;

const pdfMapping: { [key: string]: any } = {
    CatalogPimaAllProducts,
    DoorCompositePima2024
    // CeilingPanel2D,
    // CeilingPanel3D,
    // CNC,
    // Door,
    // FlutedPanelWallPanelCabinetProfileCompositeDoor,
    // Marble,
    // PimaIntroduction,
    // Shelf,
    // WallAndFlutePanel,
};

const Page = React.forwardRef<HTMLDivElement, { pageNumber: number }>(({ pageNumber }, ref) => {
    return (
        <div ref={ref}>
            <ReactPdfPage pageNumber={pageNumber} width={width} />
        </div>
    );
});

export const CatalogBook = observer(() => {
    const book = useRef<any>();
    const { pdfName } = useParams<{ pdfName: string }>(); // extract from URL
    const { t } = useTranslation();

    const selectedPdf = pdfName ? pdfMapping[pdfName] : null;

    if (!selectedPdf) {
        return <div>Invalid PDF Name</div>; // Error handling for invalid pdfName
    }

    const [numPages, setNumPages] = useState<number>(0);
    const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    };
    const [showAnimation, setShowAnimation] = useState(true);
    const [, setScreenWidth] = useState(window.innerWidth); // State to track screen width

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowAnimation(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    // Handle window resize to track screen width dynamically
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleDownload = () => {
        const a = document.createElement('a');
        a.href = selectedPdf;
        a.download = `${pdfName}.pdf`;
        a.click();
    };

    const viewPDF = () => {
        window.open(selectedPdf, '_blank'); // Opens the PDF in a new tab
    };

    return (
        <div className="CatalogBook">
            <div className="content">
                <div className="navBar">
                    <div className='btnWrapper' onClick={viewPDF}>
                        <PictureAsPdfOutlinedIcon />
                        <span>{t('View PDF')}</span>
                    </div>
                    <div className='btnWrapper' onClick={handleDownload}>
                        <FileDownloadOutlinedIcon />
                        <span>{t('Download')}</span>
                    </div>
                </div>
                <div className="left">
                    <div onClick={() => book.current.pageFlip().flipPrev()} className='btnWrapper'>
                        <SkipPreviousIcon />
                        <span>{t('Previous Page')}</span>
                    </div>
                </div>
                <div className="right">
                    <div onClick={() => book.current.pageFlip().flipNext()} className='btnWrapper'>
                        <span>{t('Next Page')}</span>
                        <SkipNextIcon />
                    </div>
                </div>
                <div className='demoPage'>
                    <Document file={selectedPdf} className="margin-auto" onLoadSuccess={onDocumentLoadSuccess}>
                        <HTMLFlipBook ref={book} width={width} height={height}>
                            {Array.from({ length: numPages }, (_, index) => (
                                <Page key={index} pageNumber={index + 1} />
                            ))}
                        </HTMLFlipBook>
                    </Document>
                </div>
            </div>
            {showAnimation && (
                <div className="animationWrapper">
                    <Lottie
                        animationData={animation_book_open}
                        loop={true}
                        className='animation'
                    />
                </div>
            )}
        </div>
    );
    
    
});
